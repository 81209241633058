import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

const NUM_OF_ROWS_DEFAULT = 4;
const textAreaClassName =
    'relative block w-full appearance-none rounded-none rounded-t-md ' +
    'border border-gray-300 ' +
    'px-3 py-2 ' +
    'text-gray-900 placeholder-gray-500 sm:text-sm ' +
    'focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500';

const TextareaField = ({ label, rows = NUM_OF_ROWS_DEFAULT, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <div>
            <label htmlFor={field.name} className="sr-only">
                {label}
            </label>
            <textarea {...field} {...props} placeholder={label} rows={rows} className={textAreaClassName} />
            {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
        </div>
    );
};

TextareaField.propTypes = {
    label: PropTypes.string.isRequired,
    rows: PropTypes.number,
};

export default TextareaField;
