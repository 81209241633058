import axios from 'axios';
import slugify from 'slugify';
import imageCompression from 'browser-image-compression';

const defaultOptions = {
    maxSizeMB: 0.3,
    useWebWorker: true,
    maxIteration: 25,
};

export const compressImage = async (imageFiles, setProgress) => {
    const images = imageFiles instanceof FileList ? Array.from(imageFiles) : null;

    if (images && images.length) {
        const promises = images.map((img) =>
            imageCompression(img, {
                ...defaultOptions,
                onProgress: (progress) => {
                    setProgress((prevState) => ({
                        ...prevState,
                        [img.name]: progress || 0,
                    }));
                },
            }),
        );
        try {
            return await Promise.all(promises);
        } catch (error) {
            console.error(error);
            return null;
        }
    } else if (imageFiles instanceof File) {
        try {
            const result = await imageCompression(imageFiles, {
                ...defaultOptions,
                onProgress: (progress) => {
                    setProgress((prevState) => ({
                        ...prevState,
                        [imageFiles.name]: progress || 0,
                    }));
                },
            });
            return [result];
        } catch (error) {
            console.error(error);
            return null;
        }
    }
    return null;
};

// const toEmail = 'patrik.christensen@gmail.com';
const toEmail = 'lars.maechel@layer10.se';
const subject = 'Registrering av skada';

const createFilename = (filename, company, typeOfImage, filenameSuffix = '') => {
    const dateStr = new Date().toLocaleDateString('sv-SE');
    const [, extension] = filename.split('.');
    const companySlug = slugify(company, {
        replacement: '-',
        remove: undefined,
        lower: true,
        strict: false,
        locale: 'se',
        trim: true,
    });
    return `${dateStr}_${companySlug}_${typeOfImage}${filenameSuffix}.${extension}`;
};

export const sendEmail = async (values) => {
    try {
        const { photoOfDamage, photoOfPortLabel, photoOfShippingManifest, ...rest } = values;
        const { company } = rest;

        const photoOfDamageWithType = Array.isArray(photoOfDamage)
            ? photoOfDamage.map((img, index) => ({ ...img, type: 'bild-skada', suffix: `-${index}` }))
            : [];
        const photoOfPortLabelWithType = Array.isArray(photoOfPortLabel)
            ? photoOfPortLabel.map((img) => ({ ...img, type: 'bild-port-etikett' }))
            : [];
        const photoOfShippingManifestWithType = Array.isArray(photoOfShippingManifest)
            ? photoOfShippingManifest.map((img) => ({ ...img, type: 'bild-fraktsedel' }))
            : [];
        const imagesAsBase64 = [
            ...photoOfDamageWithType,
            ...photoOfPortLabelWithType,
            ...photoOfShippingManifestWithType,
        ]
            .filter((file) => !!file)
            .map(({ file, src, type, suffix }) => {
                return {
                    ...file,
                    filename: createFilename(file.name, company, type, suffix),
                    content: src.split(',')[1],
                    encoding: 'base64',
                };
            });

        const body = {
            toEmail,
            subject,
            message: ' ',
            formValues: rest,
            imagesAsBase64,
        };

        try {
            const response = await axios({
                method: 'post',
                url: 'https://8523qknvdh.execute-api.eu-north-1.amazonaws.com/Prod/send',
                data: JSON.stringify(body),
            });
            return response.data;
        } catch (err) {
            if (err.response && err.response.data) {
                return err.response.data;
            }
            return { success: false, message: 'Något gick fel. Inget mail har skickats.' };
        }
    } catch (e) {
        console.error(e);
        return { success: false, message: 'Något gick fel. Inget mail har skickats.' };
    }
};

export const getTotalPropress = (progress) =>
    progress && Object.keys(progress).length > 0
        ? Math.floor(Object.values(progress).reduce((acc, val) => acc + val, 0) / Object.keys(progress).length)
        : null;

export const readFile = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (event) {
            resolve({ file, src: event.target.result });
        };
        reader.onerror = function () {
            console.error(reader.error);
            reader.abort();
            reject(`${file.name} fick inte att läsa`);
        };
        reader.readAsDataURL(file);
    });
