import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

const textFieldClassName =
    'relative block w-full appearance-none rounded-none rounded-t-md ' +
    'border border-gray-300 ' +
    'px-3 py-2 ' +
    'text-gray-900 placeholder-gray-500 ' +
    'focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm';

const TextField = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <div>
            <label className="sr-only">{label}</label>
            <input {...field} {...props} placeholder={label} className={textFieldClassName} />

            {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
        </div>
    );
};

TextField.propTypes = {
    label: PropTypes.string.isRequired,
};

export default TextField;
