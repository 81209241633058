import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

const inputClassName = 'h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500';
const labelClassName = 'ml-2 block text-sm text-gray-900';

const CheckboxField = ({ label, onChange, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const { setFieldValue, setFieldTouched } = props;

    return (
        <div className="flex items-center">
            <input
                id={field.name}
                name={field.name}
                type="checkbox"
                className={inputClassName}
                onChange={onChange({ helpers, setFieldValue, setFieldTouched })}
            />
            <label htmlFor={field.name} className={labelClassName}>
                {label}
            </label>

            {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
        </div>
    );
};

CheckboxField.propTypes = {
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
};

export default CheckboxField;
