export const initialValues = {
    name: '',
    email: '',
    company: '',
    registrationCode: '',
    orderNumber: '',
    portNumber: '',
    portLabelMissing: false,
    photoOfPortLabel: null,
    photoOfDamage: null,
    photoOfShippingManifest: null,
    description: '',
    desiredAction: '',
};
