import * as Yup from 'yup';

export const reportDamageSchema = Yup.object().shape({
    name: Yup.string().trim().required('Fältet är obligatorisk'),
    email: Yup.string().email('Fältet har fel format för email').required('Fältet är obligatoriskt'),
    company: Yup.string().trim().required('Fältet är obligatorisk'),
    registrationCode: Yup.string()
        .matches(/^([0-9]+)$/, 'Fältet får endast innehålla siffror')
        .required('Fältet är obligatorisk'),
    portLabelMissing: Yup.bool().default(false),
    orderNumber: Yup.string()
        .trim()
        .matches(/^[a-zA-Z0-9]+$/, 'Inga specialtecken tillåtna')
        .when('portLabelMissing', {
            is: true,
            then: Yup.string().required('Ordernummer är obligatoriskt'),
        }),
    portNumber: Yup.string()
        .trim()
        .matches(/^[a-zA-Z0-9]+$/, 'Inga specialtecken tillåtna')
        .when('portLabelMissing', {
            is: true,
            then: Yup.string().required('Portnummer är obligatoriskt'),
        }),
    photoOfPortLabel: Yup.array()
        .nullable()
        .min(1, 'Foto på portetikett är obligatoriskt')
        .max(1, 'Max en bild är tillåten')
        .when('portLabelMissing', {
            is: false,
            then: Yup.array().nullable().required('Foto på portetikett är obligatoriskt'),
        }),
    photoOfDamage: Yup.array()
        .nullable()
        .min(1, 'Foto på skada är obligatoriskt')
        .max(3, 'Lägg till max 3 bilder')
        .required('Foto på skada är obligatoriskt'),
    photoOfShippingManifest: Yup.array()
        .nullable()
        .min(1, 'Foto på fraktsedel är obligatoriskt')
        .max(1, 'Max 1 bild är tillåten'),
    description: Yup.string()
        .trim()
        .max(200, 'Beskrivningen får vara högst 200 tecken')
        .required('Fältet är obligatoriskt'),
    desiredAction: Yup.string()
        .trim()
        .max(200, 'Önskad åtgärd får vara högst 200 tecken')
        .required('Fältet är obligatoriskt'),
});
