import { Logo, Title } from './components';
import { ReportDamageForm } from './forms';
import './App.css';
import MessageProvider from './context/MessageContext';

const App = () => {
    return (
        <MessageProvider>
            <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="w-full max-w-lg space-y-8">
                    <Logo />
                    <Title />
                    <p>
                        Här anmäler du din reklamation (skador, saknade delar eller andra avvikelser). Efter anmälan
                        kommer du att få en bekräftelse till angiven mailadress. Behöver vi kompletterande uppgifter så
                        kontaktar vi dig.
                    </p>
                    <ReportDamageForm />
                </div>
            </div>
        </MessageProvider>
    );
};

export default App;
