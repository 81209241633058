import React from 'react';
import PropTypes from 'prop-types';

const btnClassName = 'bg-pink-700 hover:bg-pink-600 text-white py-1 px-2 rounded w-100';

const ImageCard = ({ img, deleteFile }) => {
    const { file, src } = img;
    const onClickListener = (e) => {
        e.preventDefault();
        deleteFile(file.name);
    };

    return (
        <div className="card">
            <img id={file.name} src={src} alt="" />
            <button className={btnClassName} onClick={onClickListener}>
                Ta bort
            </button>
        </div>
    );
};

ImageCard.propTypes = {
    img: PropTypes.shape({
        file: PropTypes.object.isRequired,
        src: PropTypes.string.isRequired,
    }).isRequired,
    deleteFile: PropTypes.func.isRequired,
};

export default ImageCard;
