import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compressImage, getTotalPropress, readFile } from '../forms/ReportDamageForm/utils/functions';
import ImageCard from './ImageCard';

function FileUpload(props) {
    const [images, setImages] = useState([]);
    const [progress, setProgress] = useState({});
    const { field, form } = props;
    const { touched, error } = form.getFieldMeta(field.name);
    const totalProgress = getTotalPropress(progress);
    const inputId = field.name.toLowerCase();

    useEffect(() => {
        if (!touched) {
            setImages([]);
            const inputField = document.getElementById(inputId);
            const dt = new DataTransfer();
            inputField.files = dt.files;
        }
    }, [touched]);

    const handleChange = async (e) => {
        form.setFieldTouched(field.name, true, true);
        const files = e.currentTarget.files;
        const compressed = await compressImage(files, setProgress);

        if (!Array.isArray(compressed)) {
            form.setFieldValue(field.name, '');
            setImages([]);
            setProgress({});
            return;
        }

        try {
            const result = await Promise.all(Array.from(compressed).map((file) => readFile(file)));
            form.setFieldValue(field.name, result);
            setImages(result);
            setProgress({});
        } catch (e) {
            console.error(e);
        }
    };

    const handleBlur = () => {
        const touched = true;
        const shouldValidate = true;
        form.setFieldTouched(field.name, touched, shouldValidate);
    };

    const deleteFile = (filename) => {
        const filtered = images.filter((img) => img.file.name !== filename);
        setImages(filtered);
        form.setFieldValue(field.name, filtered);
        const inputField = document.getElementById(inputId);
        if (inputField) {
            const dt = new DataTransfer();
            const { files } = inputField;
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (file.name !== filename) {
                    dt.items.add(file);
                }
            }

            inputField.files = dt.files;
        }
    };

    const renderImages = () => {
        if (images && Array.isArray(images)) {
            return images.map((img) => <ImageCard img={img} key={img.file.name} deleteFile={deleteFile} />);
        }
    };

    return (
        <div>
            <label className="block my-1.5 font-bold" htmlFor={field.name}>
                {props.label} {totalProgress ? `[${totalProgress}%]` : ''}
            </label>
            <input
                id={inputId}
                type="file"
                onChange={(event) => handleChange(event)}
                onBlur={(event) => handleBlur(event)}
                accept="image/*;capture=camera"
                {...(props.multiple ? { multiple: true } : {})}
            />
            {touched && error ? <div className="error">{error}</div> : null}
            <ul className="img-container">{renderImages()}</ul>
        </div>
    );
}

FileUpload.propTypes = {
    label: PropTypes.string.isRequired,
    form: PropTypes.object,
    field: PropTypes.object,
    multiple: PropTypes.bool,
};

export default FileUpload;
