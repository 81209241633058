import { initialValues } from './constants';
import { sendEmail } from './functions';

export const onSubmitHandler =
    (setResponse) =>
    async (values, { resetForm, setSubmitting }) => {
        const response = await sendEmail(values);
        setResponse(response);
        if (response.success) {
            resetForm({ values: initialValues });
        }
        setSubmitting(false);
    };

export const onResetHandler = () => {
    resetCheckboxField();
    resetPhotoFields();
};

export const resetCheckboxField = () => {
    const input = document.getElementById('portLabelMissing');
    input.checked = false;
};

export const resetPhotoFields = () => {
    const fieldNames = Object.keys(initialValues)
        .filter((prop) => prop.includes('photo'))
        .map((prop) => prop.toLowerCase());

    for (let name of fieldNames) {
        const inputTag = document.getElementById(name);
        if (inputTag) {
            inputTag.value = '';
        }

        const imgTag = document.getElementById(`${name}_img`);
        if (imgTag) {
            imgTag.style.display = 'none';
            imgTag.src = '';
        }

        const btnTag = document.getElementById(`${name}_button`);
        if (btnTag) {
            btnTag.style.display = 'none';
        }
    }
};

export const onMissingLabelChange = (props) => (event) => {
    const isChecked = event.target.checked;

    const { helpers, setFieldValue, setFieldTouched } = props;
    helpers.setValue(isChecked);

    if (isChecked) {
        setFieldValue('orderNumber', '');
        setFieldValue('portNumber', '');
        setFieldTouched('orderNumber');
        setFieldTouched('portNumber');
    } else {
        setFieldValue('photoOfPortLabel', '');
        setFieldTouched('photoOfPortLabel');
    }
};
