import React from 'react';

const formTitleClassName = 'text-center text-3xl font-bold tracking-tight text-gray-900';

const Title = () => {
    return (
        <h3 className={formTitleClassName} style={{ marginTop: 0 }}>
            Reklamationsanmälan
        </h3>
    );
};

export default Title;
