import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import { reportDamageSchema } from '../../validation';
import { CheckboxField, FileUpload, TextareaField, TextField } from '../../components';
import { constants, eventHandlers } from './utils';
import { useAppMessage } from '../../hooks';
import 'react-toastify/dist/ReactToastify.css';

const submitBtnClassName =
    'mr-1 min-w-[100px] py-1 px-2 bg-blue-600 text-amber-50 shadow rounded disabled:opacity-30 disabled:bg-gray-500';
const resetBtnClassName =
    'min-w-[100px] py-1 px-2 bg-pink-700 text-amber-50 shadow rounded disabled:opacity-30 disabled:bg-gray-500';

const ReportDamageForm = () => {
    const { initialValues } = constants;
    const { setError, setMessage } = useAppMessage();
    const [response, setResponse] = useState(null);
    const { onSubmitHandler, onResetHandler, onMissingLabelChange } = eventHandlers;
    const submitHandler = onSubmitHandler(setResponse);

    useEffect(() => {
        if (response && response.success) {
            setMessage(response.message);
            toast.success(response.message);
        } else if (response && !response.success) {
            setError(response.message);
            toast.error(response.message);
        }
    }, [response]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={reportDamageSchema}
            onSubmit={submitHandler}
            onReset={onResetHandler}>
            {({ handleSubmit, isSubmitting, errors, touched, values, setFieldValue, setFieldTouched }) => {
                const hasErrors = !!Object.keys(errors).length;
                const isUntouched = !Object.keys(touched).length;
                const btnDisabled = isSubmitting || hasErrors || isUntouched;
                return (
                    <>
                        <ToastContainer
                            position="bottom-center"
                            autoClose={10000}
                            hideProgressBar
                            newestOnTop={true}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="colored"
                        />
                        <Form className="mt-8 space-y-10" onSubmit={handleSubmit}>
                            <TextField name="name" type="text" label="Namn" />
                            <TextField name="email" type="email" label="Email" />
                            <TextField name="company" type="text" label="Företag" />
                            <TextField name="registrationCode" type="text" label="Kod" />
                            <CheckboxField
                                name="portLabelMissing"
                                type="checkbox"
                                label="Portetikett saknas"
                                onChange={onMissingLabelChange}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                            />

                            {values.portLabelMissing ? (
                                <>
                                    <TextField name="orderNumber" type="text" label="Ordernummer" />
                                    <TextField name="portNumber" type="text" label="Portnummer" />
                                </>
                            ) : (
                                <Field name="photoOfPortLabel" label="Foto på portetikett" component={FileUpload} />
                            )}

                            <Field name="photoOfDamage" label="Foto på skada" multiple={true} component={FileUpload} />

                            <Field
                                name="photoOfShippingManifest"
                                label="Foto på fraktsedel (endast vid fraktskador)"
                                component={FileUpload}
                            />

                            <TextareaField name="description" type="text" label="Reklamationsorsak (max 200 tecken)" />
                            <TextareaField
                                name="desiredAction"
                                type="text"
                                label="Önskad åtgärd (max 200 tecken), tex ny sektion (ange nr/position), ersättning av saknad del eller ekonomisk kompensation till kunden."
                            />

                            <div className="flex justify-between w-1/3 align-middle">
                                <div>
                                    <button type="submit" className={submitBtnClassName} disabled={btnDisabled}>
                                        Skicka
                                    </button>
                                </div>

                                <div>
                                    <button className={resetBtnClassName} disabled={isUntouched} type="reset">
                                        Börja om
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </>
                );
            }}
        </Formik>
    );
};

export default ReportDamageForm;
