import React, { createContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

export const MessageContext = createContext({
    error: '',
    message: '',
    setError: () => {},
    setMessage: () => {},
});

const MessageProvider = ({ children }) => {
    const [applicationError, setApplicationError] = useState('');
    const [applicationMessage, setApplicationMessage] = useState('');

    const setError = (error) => setApplicationError(error);
    const setMessage = (message) => setApplicationMessage(message);

    const contextValue = {
        error: applicationError,
        message: applicationMessage,
        setError: useCallback((error) => {
            setError(error);
        }, []),
        setMessage: useCallback((message) => {
            setMessage(message);
        }, []),
    };

    return <MessageContext.Provider value={contextValue}>{children}</MessageContext.Provider>;
};

MessageProvider.propTypes = {
    children: PropTypes.object,
};

export default MessageProvider;
